<template>
    <div class="upload-body">
        <div class="page-title">商品批量上传</div>

        <div class="upload-header" v-if="freightTemplateId">
            <excel-uploader :total="progress.total" :finished="progress.finished"/>
            <div>
                <div>商品条码</div>
                <div>
                    <at-input v-model="searchKeywords" ref="codeInput" class="code-input" @change="fetchTmp">
                        <a-button class="code-btn" @click="fetchTmp"></a-button>
                    </at-input>
                </div>
            </div>
        </div>

        <div v-if="notFoundGoods" class="notfound-goods">
            暂无任何数据.
        </div>
        <div class="upload-table" v-else-if="paramsReady">
            <table class="skus-table">
                <thead>
                <tr>
                    <th colspan="6">
                        <div class="goods-pops">
                            <div class="goods-pop-item">
                                <div class="attr-name">商品名称：</div>
                                <div class="attr-value">{{ params.title }}</div>
                            </div>
                            <div class="goods-pop-item">
                                <div class="attr-name">款号：</div>
                                <div class="attr-value">{{ params.skus[0].sku_code }}</div>
                            </div>
                            <div class="goods-pop-item">
                                <div class="attr-name">分类选择：</div>
                                <div>
                                    <a-cascader
                                        :displayRender="selectRender"
                                        class="cate-cascader"
                                        placeholder="请选择分类"
                                        :options="categories"
                                        :load-data="loadCateData"
                                        :change-on-select="true"
                                        @change="cateOnChange"
                                        :fieldNames="{label:'name',value:'cat_id',children: 'children'}"
                                    />
                                </div>
                            </div>
                        </div>
                    </th>
                </tr>
                <tr class="table-titles">
                    <th style="width: 150px">图片</th>
                    <th>颜色</th>
                    <th>尺码</th>
                    <th>价格</th>
                    <th>库存</th>
                    <th>条码</th>
                </tr>
                </thead>
                <tbody v-if="tablePops">
                <template v-for="(sku,sk) in params.skus">
                    <tr :key="sk">
                        <td v-if="tablePops[sk]['cp'] > 0 " :rowspan="tablePops[sk]['cp']">
                            <div class="sku-value td-uploader">
                                <images-uploader :max="1" @change="list => skuImageChanged(list,tablePops[sk])"/>
                                <span class="uploader-tips">限制在2M以内</span>
                            </div>
                        </td>
                        <td v-if="tablePops[sk]['cp'] > 0 " :rowspan="tablePops[sk]['cp']">
                            <div class="sku-value">
                                {{ tablePops[sk]['color'] }}
                            </div>
                        </td>
                        <td v-if="tablePops[sk]['sp'] > 0 " :rowspan="tablePops[sk]['sp']">
                            <div class="sku-value">
                                {{ tablePops[sk]['size'] }}
                            </div>
                        </td>
                        <td>
                            <div class="sku-value">{{ sku.sale_price }}</div>
                        </td>
                        <td>
                            <div class="sku-value">{{ sku.stock_num }}</div>
                        </td>
                        <td>
                            <div class="sku-value">{{ sku.sku_code }}</div>
                        </td>
                    </tr>
                </template>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="6">
                        <div class="sku-value">
                            <button @click="confirm" class="btn submit-btn">提交</button>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </table>

            <div class="content-images">
                <div class="content-images-title">
                    <span>商品详情图</span>
                    <span class="tips">（图片大小需限制在2M以内,最多20张）</span>
                </div>
                <div class="images-list">
                    <images-uploader v-model=" params.desc_info.imgs " :max="20"/>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import ExcelUploader from "./Com/ExcelUploader";
import ImagesUploader from "./Com/ImagesUploader";
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";

export default {
    name: "Upload",
    components: {AtInput, ExcelUploader, ImagesUploader},
    data() {
        return {
            routeMatch: '/ast/shop/goods/review',
            progress: {
                total: 0,
                finished: 0,
            },
            categories: [
                {cat_id: 6033, name: "服饰内衣", f_cat_id: 0, isLeaf: false},
                {cat_id: 6870, name: "美妆护肤", f_cat_id: 0, isLeaf: false},
                {cat_id: 6831, name: "箱包皮具", f_cat_id: 0, isLeaf: false},
                {cat_id: 7378, name: "鞋靴", f_cat_id: 0, isLeaf: false},
                {cat_id: 378136, name: "珠宝首饰", f_cat_id: 0, isLeaf: false},
            ],
            notFoundGoods: true,
            freightTemplateId: null,
            paramsReady: false,
            searchKeywords: "",
            lastKeywords: "",
            params: {
                title: "",
                head_img: [],
                brand_id: 2100000000,
                cats: [
                    {
                        cat_id: 6033,
                        level: 1
                    },
                    {
                        cat_id: '',
                        level: 2
                    },
                    {
                        cat_id: '',
                        level: 3
                    }
                ],
                express_info: {
                    template_id: ''
                },
                attrs: [
                    {
                        attr_key: "尺码",
                        attr_value: "均码"
                    }
                ],
                skus: [
                    {
                        sale_price: '',
                        stock_num: '',
                        sku_code: '',
                        sku_attrs: [
                            {
                                attr_key: "尺码",
                                attr_value: "均码"
                            }
                        ]
                    }
                ],
                desc_info: {
                    imgs: []
                }
            },
            tablePops: null,
        }
    },
    mounted() {
        this.params = null
        this.fetchUploadProgress()
        this.fetchFreightTemplate()
    },
    methods: {
        skuImageChanged(images, prop) {

            let contentImages = this.params.desc_info.imgs;

            contentImages = contentImages.concat(images)

            this.params.desc_info.imgs = contentImages.filter(function (item, index) {
                return contentImages.indexOf(item) === index;
            })

            let idx = this.tablePops.indexOf(prop)

            if (idx < 0) return;

            for (let i = 0; i < prop.cp; i++) {

                let index = +idx + i;

                let sku = this.params.skus[index]

                if (!sku) continue

                sku.thumb_img = images[0] || ''
            }

            let headImages = [];

            for (let sku of this.params.skus) {
                if (sku.thumb_img && headImages.indexOf(sku.thumb_img) < 0) {
                    headImages.push(sku.thumb_img)
                }
            }

            this.params.head_img = headImages;

            console.log(this.params.skus);
        },
        fetchFreightTemplate() {
            api.get('/ast-shop/freight-template', (data) => {

                if (data.data) {
                    for (let tpl of data.data) {

                        if (!tpl.is_default) continue;

                        this.freightTemplateId = tpl.template_id

                    }

                }
                if (!this.freightTemplateId) {
                    this.$message.error('获取默认运费模板失败，请重试.');
                }

                if (this.params && !this.params.express_info.template_id) {
                    this.params.express_info.template_id = this.freightTemplateId
                }

            })
        },
        selectRender({labels}) {
            return labels.join(' - ')
        },
        fetchUploadProgress() {
            api.get('/ast-shop/upload-progress', {}, data => {
                this.progress = data.data
            })
        },
        fetchTmp() {
            if (!this.searchKeywords) return

            if (this.lastKeywords === this.searchKeywords) return;

            this.paramsReady = false

            this.$loading.show()

            this.params = null

            let search = this.searchKeywords

            this.lastKeywords = this.searchKeywords

            api.get('/ast-shop/tmp-data', {
                code: this.searchKeywords
            }, (data) => {

                if (this.searchKeywords !== search) return;

                this.$loading.hide()

                this.notFoundGoods = !data.data

                if (this.notFoundGoods) return
                console.log(data.data.exists);
                if (data.data.exists) {
                    this.$confirm({
                        title: '本店商品库里已存在该款号条码商品，请注意勿重复提交',
                        content: '（如小商店今日刚删除商品，数据库更新延迟也会出现提醒）',
                        onOk: () => {
                            this.setParams(data.data)
                        }
                    })
                } else {
                    this.setParams(data.data)
                }
            })
        },
        setParams(data) {

            delete data['exists']

            if (!data.head_img) data.head_img = []

            if (!data.attrs) data.attrs = []

            if (!data.desc_info.imgs) data.desc_info.imgs = []

            if (!data.express_info.template_id) {
                data.express_info.template_id = this.freightTemplateId
            }

            if (this.params) data.cats = this.params.cats

            this.calcTablePops(data)

            this.paramsReady = true
        },
        calcTablePops(data) {

            let skus = this.groupData(data.skus, 1)
            skus = this.groupData(skus, 0)

            let props = []

            for (let idx = 0; idx < skus.length; idx++) {

                let item = skus[idx]

                let sc = this.skuGroupAttr(item)

                let before = props[+idx - 1] || {
                    color: '',
                    size: '',
                }

                let prop = {
                    color: sc[1],
                    size: sc[2],
                    cp: sc[1] === before.color ? 0 : 1,
                }

                prop.sp = (prop.cp <= 0 && sc[2] === before.size) ? 0 : 1


                if (prop.sp <= 0 && prop.cp <= 0) {
                    props.push(prop)
                    continue;
                }

                for (let i = (+idx + 1); i < skus.length; i++) {

                    let child = skus[i]

                    let sc2 = this.skuGroupAttr(child)

                    // 颜色相同
                    if (sc[1] === sc2[1]) {

                        if (prop.cp > 0) prop.cp++;

                        // 尺码相同
                        if (sc[2] === sc2[2]) {

                            if (prop.sp > 0) prop.sp++;

                        }

                    }


                }

                props.push(prop)
            }

            console.log(props);

            this.tablePops = props

            data.skus = skus

            this.params = data
        },
        groupData(data, ax) {

            for (let idx = 0; idx < data.length; idx++) {

                let item = data[idx]

                let sc = this.skuGroupAttr(item)

                for (let i = (+idx + 1); i < data.length; i++) {

                    let child = data[i]

                    let sc2 = this.skuGroupAttr(child)

                    if (sc[ax] === sc2[ax]) {

                        child = data.splice(i, 1)[0]

                        data.splice(idx, 0, child)

                    }
                }
            }

            return data;
        },
        skuGroupAttr(item) {

            let color = '', size = ''

            for (let attr of item.sku_attrs) {
                if (attr.attr_key === '颜色') color = attr.attr_value
                if (attr.attr_key === '尺码') size = attr.attr_value
            }


            return [color + size, color, size]
        },
        loadCateData(selectedOptions) {

            let level = selectedOptions.length

            const targetOption = selectedOptions[selectedOptions.length - 1];


            targetOption.loading = true;

            this.fetchCategories(targetOption['cat_id'], (data) => {

                let items = data.data

                for (let item of items) {
                    item.isLeaf = (level === 2)
                }

                targetOption.children = items;

                targetOption.loading = false;

                this.categories = [...this.categories];
            })

        },
        cateOnChange(selectedOptions) {
            this.params.cats[0].cat_id = selectedOptions[0]
            this.params.cats[1].cat_id = selectedOptions[1]
            this.params.cats[2].cat_id = selectedOptions[2]
        },
        fetchCategories(parent, callback) {
            api.get('/ast-shop/categories', {parent_id: parent}, callback);
        },
        confirm() {

            if (!this.params.head_img.length) {
                return this.$message.error('请至少上传一张SKU图');
            }

            this.$loading.show()

            api.post('/ast-shop/upload-goods', this.params, (data) => {

                this.$loading.hide()

                if (data.code === 0) {

                    this.$message.success('上传成功！')

                    this.reset()

                    return
                }

                this.$message.error(data.msg)
            })
        },
        reset() {
            this.searchKeywords = ''
            this.paramsReady = false
            this.$refs.codeInput.focus()
            this.fetchUploadProgress()
        },
    },
    computed: {}
}
</script>

<style lang="less" scoped>
.upload-body {
    max-width: 1000px;
}

.code-input {
    width: 296px;
    margin-top: 24px;
}


.code-btn {
    width: 40px;
    height: 40px;


    margin-top: -1px;
    border-radius: 0;

    border: 1px solid #EEEEEE;
    background-color: #333333;
    background-image: url("/assets/icons/btn-search.png");
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
}

.upload-header {
    display: flex;
    justify-content: space-between;
}

.skus-table {
    width: 100%;
    border: 1px solid #EEEEEE;
    margin-top: 24px;

    td, th {
        border: 1px solid #EEEEEE;
        text-align: center;
    }

    .table-titles th {
        height: 40px;
    }

    .attr-name, .attr-value {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
}

.goods-pops {
    display: flex;
    justify-content: center;
    padding: 13px 16px;
}

.goods-pop-item {
    display: flex;
    margin-right: 80px;
    align-items: center;

    &:last-child {
        margin-right: 0;
    }
}

.cate-cascader {
    width: 230px;
    text-align: left;

    /deep/ input {
        border: none;
    }
}

.sku-value {
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.td-uploader {

    padding-top: 15px;
    padding-bottom: 15px;

    line-height: 0;

    /deep/ .upload-image-item {
        margin: 0;
    }
}

.uploader-tips {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    position: relative;
    margin-top: 6px;
}

.submit-btn {
    margin-top: 8px;
    margin-bottom: 8px;
}

.content-images {
    margin-top: 32px;

    .content-images-title {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;

        .tips {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
        }

    }

    .images-list {
        margin-top: 12px;
    }
}

.notfound-goods {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}
</style>